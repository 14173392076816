import moment from 'moment';

var roleMap = {
  leader: 'Leider',
  leaders: 'Leiding',
  member: 'Lid',
  members: 'Leden',
  admin: 'Beheerder',
  admins: 'Beheerders',
  parent: 'Ouder',
  parents: 'Ouders',
  contact: 'Contact',
  contacts: 'Contacten',
  father: 'Papa',
  fathers: "Papa's",
  mother: 'Mama',
  mothers: "Mama's",
  subscriber: "Volger",
  subscribers: "Volgers"
}

const mainMixin = {
  data() {
    return {
      userRoles: ['member', 'leader', 'admin'],
      groupRoles: ['member', 'leader'],
      contactRoles: ['parent', 'mother', 'father', 'contact'],
      roleOptions: [
          { label: this.getRoleSingular('admin'), value: 'admin' },
          { label: this.getRoleSingular('leader'), value: 'leader' },
          { label: this.getRoleSingular('member'), value: 'member' },
          { label: this.getRoleSingular('parent'), value: 'parent' },
          { label: this.getRoleSingular('subscriber'), value: 'subscriber' },
      ],
      genderOptions: [
        { text: "Man", value: "M" },
        { text: "Vrouw", value: "F" },
        { text: "Andere", value: "X" },
      ],
    }
  },
  methods: {
    isModuleEnabled(module){
      var res = false;
      if (this.$store.state.organisation != null &&
        this.$store.state.organisation.modules != null &&
        this.$store.state.organisation.modules[module] != null &&
        this.$store.state.organisation.modules[module]['enabled']) {
      res = true;
    } else if (this.$store.state.organisation == null || this.$store.state.organisation.modules == null || this.$store.state.organisation.modules[module] == null) {
      // Enabled if config is not set
      res = true;
    }
    return res;
    },
    formatDate(date, format = "dd D/MM/YY (HH:mm)") {
      if (date.seconds) {
        date = new Date(date.seconds * 1000);
      }
      return moment(date).format(format);
    },
    fetchUsers() {
      if (!(
        this.$store.state.userManagement.users &&
        this.$store.state.userManagement.users.length > 1)
      ) {
        this.$store
          .dispatch(
            "userManagement/fetchUsers",
            this.$store.state.organisation.id
          );
      }
    },
    searchArray(arr, searchQuery) {
      return arr.filter((item) => {
        return (
          (item.displayName &&
            item.displayName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (item.firstname && item.firstname
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
          (item.lastname &&
            item.lastname
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())) ||
          (item.email && item.email.toString().toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item.phone && item.phone.toString().toLowerCase().includes(searchQuery.toLowerCase()))
        );
      });
    },
    titleCase: function (str) {
      if(str){
        var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
      } else {
        return str;
      }
      
    },
    getRoleSingular: function (role) {
      if (this.$store.state.organisation) {
        return this.$store.state.organisation.roles[role] ? this.titleCase(this.$store.state.organisation.roles[role]) : roleMap[role];
      } else {
        return roleMap[role]
      }

    },
    getRoleMultiple: function (role) {
      if (this.$store.state.organisation) {
        return this.$store.state.organisation.roles[role + "s"] ? this.titleCase(this.$store.state.organisation.roles[role + "s"]) : this.$store.state.organisation.roles[role] ? this.titleCase(this.$store.state.organisation.roles[role] + "s") : roleMap[role + "s"];
      }
      return roleMap[role + "s"];
    },
    resolveUserRoleIcon: function (role) {
      if (role === 'admin') return 'SettingsIcon'
      if (role === 'leader') return 'UsersIcon'
      if (role === 'member') return 'UserIcon'
      if (role === 'parent' || role == 'contact') return 'Edit2Icon'
      return 'UserIcon'
    },
    resolveUserRoleVariant: function (role) {
      if (role === 'admin') return 'danger'
      if (role === 'leader') return 'warning'
      if (role === 'member') return 'success'
      if (role === 'parent' || role == 'contact') return 'primary'
      return 'secondary';
    },
    resolveContactRoleIcon: function (role) {
      if (role === 'parent' || role == 'father' || role == 'mother') return 'UsersIcon'
      return 'UserIcon'
    },
    resolveContactRoleVariant: function (role) {
      if (role === 'parent' || role == 'father' || role == 'mother') return 'primary'
      return 'secondary';
    },
    resolveUserStatusVariant: function (status) {
      if (status === 'active') return 'success'
      else return 'secondary'
    },
    getGroupColor: function (store, id) {
      if (store.state.groups) {
        var index = store.state.groups.findIndex((element) => element.id == id);
        if (index > -1) {
          return store.state.groups[index].color
        } else return null

      } else return null;
    },
    getGroupActive: function (store, id) {
      if (store.state.groups) {
        var index = store.state.groups.findIndex((element) => element.id == id);
        if (index > -1) {
          return store.state.groups[index].active
        } else return null

      } else return null;
    },
    getInitials(name) {
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
      let initials = null;
      if (name) {
        initials = [...name.matchAll(rgx)] || [];

        initials = (
          (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();
      }


      return initials ?? "";

    },
    generateUUID() { // Public Domain/MIT
      var d = new Date().getTime();
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); //use high-precision timer if available
      }
      var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });

      return newGuid;
    },
  }
}
export default mainMixin;
