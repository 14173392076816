<template>
  <b-card>
    <b-form @submit.prevent="saveOrganisation">
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon icon="ToolIcon" size="18" />
            <h4 class="mb-0 ml-75">Configuratie</h4>
          </div>
        </b-col>

        <b-col cols="12" class="mt-2">
          <b-form-checkbox
            v-model="organisation.modules.posts.enabled"
            class="custom-control-success"
          >
            <h4 class="mb-1">Nieuwsberichten</h4>
          </b-form-checkbox>
          <b-form-checkbox
            :disabled="!enabled || !organisation.modules.posts.enabled"
            v-model="organisation.modules.posts.defaultCommentsEnabled"
            class="custom-control-success mb-1 ml-1"
          >
            Reacties
          </b-form-checkbox>
          <b-form-checkbox
          :disabled="!enabled || !organisation.modules.posts.enabled"
            v-model="organisation.modules.posts.commentsEnabledLeaderChangeable"
            class="custom-control-success mb-1 ml-1"
          >
            {{getRoleMultiple("leader")}} kunnen reacties in-/uitschakelen
          </b-form-checkbox>
          <b-form-checkbox
          :disabled="!enabled || !organisation.modules.posts.enabled"
            v-model="organisation.modules.posts.videoEnabled"
            class="custom-control-success mb-1 ml-1"
          >
            Video's
          </b-form-checkbox>
          <b-form-checkbox
          :disabled="!enabled || !organisation.modules.posts.enabled"
            v-model="organisation.modules.posts.faceRecognitionEnabled"
            class="custom-control-success mb-1 ml-1"
          >
            Automatische gezichtsherkenning
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-form-checkbox
          :disabled="!enabled"
            v-model="organisation.modules.calendar.enabled"
            class="custom-control-success"
          >
            <h4 class="mb-1">Kalender</h4>
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-form-checkbox
          :disabled="!enabled"
            v-model="organisation.modules.presences.enabled"
            class="custom-control-success"
          >
            <h4 class="mb-1">Aanwezigheden</h4>
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" class="mt-2">
          <b-form-checkbox
          :disabled="!enabled"
            v-model="organisation.modules.chat.enabled"
            class="custom-control-success"
          >
            <h4 class="mb-1">Chat</h4>
          </b-form-checkbox>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            :disabled="loading"
            class="mt-1 mr-1"
          >
            <b-spinner v-if="loading" small></b-spinner>
            Opslaan
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Annuleren
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import mainMixin from '@/mixins/mainMixin';
import moment from "moment";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BSpinner,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  mixins: [mainMixin],
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BSpinner,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      organisation: this.$store.state.organisation,
    };
  },
  computed: {
    enabled(){
      if(this.$store.state.organisation != null && this.$store.state.organisation.plan != 'free'){
        return true;
      }
      return false;
    }
  },
  created(){
    if(!this.organisation.modules){
      this.organisation.modules = {
        posts: {
          enabled: true,
          commentsEnabledLeaderChangeable: true,
          defaultCommentsEnabled: true,
          faceRecognitionEnabled: false,
        },
        presences: {
          enabled: true
        },
        calendar: {
          enabled: true
        },
        chat: {
          enabled: true
        }
      }
    }
  },
  methods: {
    async saveOrganisation(){
        this.loading=true;
         await this.$store.dispatch('updateOrganisation', {
           organisation: this.organisation,
           notify: (arg) => this.$toast(arg)});
           this.loading = false;
       
    },
    resetForm() {
      this.o = { ...this.$store.state.organisation, ...{} };
    },
  },
};
</script>
