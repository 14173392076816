<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="EditIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{$store.state.organisation.shortName}}</span>
      </template>

      <organisation-settings-general
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change branding tab -->
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="EyeIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Huisstijl</span>
      </template>

      <organisation-settings-branding />
    </b-tab>
    <!--/ change branding tab -->

    <!-- info -->
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Personalisatie</span>
      </template>

      <organisation-settings-roles
      />
    </b-tab>
    <!-- info -->
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ToolIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Modules</span>
      </template>

      <organisation-settings-modules
      />
    </b-tab>

     <!-- info -->
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Aangepaste velden</span>
      </template>

      <organisation-settings-custom-fields
      />
    </b-tab>
     <!-- info -->
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ClipboardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Inschrijvingsformulier</span>
      </template>

      <organisation-settings-sign-up-form
      />
    </b-tab>


    <!-- social links -->
    <b-tab lazy>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="DollarSignIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Facturering</span>
      </template>

      <organisation-settings-invoice
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import OrganisationSettingsGeneral from './OrganisationSettingsGeneral.vue'
import OrganisationSettingsRoles from './OrganisationSettingsRoles.vue'
import OrganisationSettingsInvoice from './OrganisationSettingsInvoice.vue'
import OrganisationSettingsBranding from './OrganisationSettingsBranding.vue'
import OrganisationSettingsModules from './OrganisationSettingsModules.vue'
import OrganisationSettingsCustomFields from './OrganisationSettingsCustomFields.vue'
import OrganisationSettingsSignUpForm from './OrganisationSettingsSignUpForm.vue'

export default {
  components: {
    BTabs,
    BTab,
    OrganisationSettingsGeneral,
    OrganisationSettingsRoles,
    OrganisationSettingsInvoice,
    OrganisationSettingsBranding,
    OrganisationSettingsModules,
    OrganisationSettingsCustomFields,
    OrganisationSettingsSignUpForm
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {
    
  },
}
</script>
