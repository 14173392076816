<template>
  <b-card>
    <!-- form -->
    <b-form
      @submit.prevent="saveOrganisation">
      <b-row>
        <b-col sm="12">
          <h4 class="mb-1">Leden</h4>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Enkelvoud" label-for="o-member-singular">
            <b-form-input
              v-model="o.roles.member"
              :disabled="loading"
              :class="$v.o.roles.member.$error ? 'is-invalid' : ''"
              placeholder="Lid"
              name="o-member-singular"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Meervoud" label-for="o-member-multiple">
            <b-form-input
              v-model="o.roles.members"
              name="o-member-multiple"
              :class="$v.o.roles.members.$error ? 'is-invalid' : ''"
              :disabled="loading"
              placeholder="Leden"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
         <h4 class="mb-1 mt-2">Leiding</h4>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Enkelvoud" label-for="o-member-singular">
            <b-form-input
              v-model="o.roles.leader"
              :disabled="loading"
              :class="$v.o.roles.leader.$error ? 'is-invalid' : ''"
              placeholder="Leiders"
              name="o-member-singular"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Meervoud" label-for="o-member-multiple">
            <b-form-input
              v-model="o.roles.leaders"
              name="o-member-multiple"
              :class="$v.o.roles.leaders.$error ? 'is-invalid' : ''"
              :disabled="loading"
              placeholder="Leiders"
            />
          </b-form-group>
        </b-col>

        <b-col sm="12">
          <h4 class="mb-1 mt-2">Beheerders</h4>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Enkelvoud" label-for="o-member-singular">
            <b-form-input
              v-model="o.roles.admin"
              :disabled="loading"
              :class="$v.o.roles.admin.$error ? 'is-invalid' : ''"
              placeholder="Beheerder"
              name="o-member-singular"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Meervoud" label-for="o-member-multiple">
            <b-form-input
              v-model="o.roles.admins"
              name="o-member-multiple"
              :class="$v.o.roles.admins.$error ? 'is-invalid' : ''"
              :disabled="loading"
              placeholder="Beheerders"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            :disabled="loading"
            class="mt-1 mr-1"
          >
           <b-spinner v-if="loading" small></b-spinner>
            Opslaan
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Annuleren
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import moment from "moment";
import { auth, db } from "@/firebase/firebaseConfig";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import { Dutch } from "flatpickr/dist/l10n/nl.js";

import {required, email, minLength, numeric} from 'vuelidate/lib/validators'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line import/no-extraneous-dependencies

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      o: { ...this.$store.state.organisation, ...{} },
    };
  },
  methods: {
    async saveOrganisation(){
      if (!this.$v.$invalid) {
        this.loading=true;
         await this.$store.dispatch('updateOrganisation', {
           organisation: this.o,
           notify: (arg) => this.$toast(arg)});
           this.loading = false;
      } else {
        this.$v.$touch();
      }
       
    },
    resetForm() {
      this.o = { ...this.$store.state.organisation, ...{} };
    },
  },
  validations: {
    o:{
      roles: {
        member: {},
        members: {},
        leader: {},
        leaders: {},
        admin: {},
        admins: {},
      }
    }
  }
};
</script>

