<template>
<b-overlay :show="loading" rounded="sm">
  <b-card>
    
    <div class="d-flex align-items-center mb-2">
            <feather-icon icon="EyeIcon" size="18" />
            <h4 class="mb-0 ml-75">Logo</h4>
          </div>
     <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar
            ref="previewEl"
            size="100px"
            variant="light-primary"
            :text="orgInitals"
            :src="profilePic"
            :disabled="loading"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
           :disabled="loading"
          @click="$refs.refInputEl.$el.click()"
        >
          Aanpassen
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
           :disabled="loading"
          @input="inputImageRenderer"
        />
        <!--/ upload button -->
        <b-card-text>JPG of PNG bestanden</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->
    <!-- form -->
    <b-form
    class="mt-2"
      @submit.prevent="saveOrganisation">
      <b-row>
        <b-col sm="12">
        <div class="d-flex align-items-center mb-2 mt-2">
            <feather-icon icon="DropletIcon" size="18" />
            <h4 class="mb-0 ml-75">Kleur</h4>
          </div>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Hoofdkleur" label-for="o-primaryColor">
            <input-color-picker v-model="o.primaryColor" :class="$v.o.primaryColor.$error ? 'is-invalid' : ''" name="o-primaryColor" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="loading"
            type="submit"
          >
          <b-spinner v-if="loading" small></b-spinner>
            Opslaan
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Annuleren
          </b-button>
        </b-col>
      </b-row>
    </b-form>
   
  </b-card>
   </b-overlay>
</template>

<script>
import moment from 'moment';
import {required} from 'vuelidate/lib/validators'
import InputColorPicker from 'vue-native-color-picker'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BSpinner,
  BAlert,
  BFormFile,
  BMedia,
    BMediaAside,
    BMediaBody,
    BOverlay
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BSpinner,
    BAlert,
    InputColorPicker,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BOverlay
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      o: {primaryColor: this.$store.state.organisation.primaryColor},
      profileFile: null,
      profilePic: this.$store.state.organisation.logo ? this.$store.state.organisation.logo.medium : null,
    };
  },
  methods: {
    async saveOrganisation(){
      if (!this.$v.$invalid) {
        this.loading=true;
         await this.$store.dispatch('updateOrganisation', {
           organisation: this.o,
           notify: (arg) => this.$toast(arg)});
           this.loading = false;
      } else {
        this.$v.$touch();
      }
       
    },
    resetForm() {
      this.o = {primaryColor: this.$store.state.organisation.primaryColor};
    },
    inputImageRenderer(){
      const file = this.profileFile
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.profilePic = reader.result;
          this.uploadFile();
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    uploadFile(){
      this.loading = true;
      this.$store.dispatch('uploadImageToPath', {
        imageName: `/organisations/${this.$store.state.organisation.id}/logo/${this.$store.state.organisation.id}.${this.profileFile.name.split('.').pop()}`,
        file: this.profileFile,
       
      }).then((result) => {
        return this.$store.dispatch('updateOrganisation', {organisation: {logo: result},  notify: (arg) => this.$toast(arg)})
      }).then(() => {
        this.loading = false;
      }).catch(error => this.loading = false)
    },
    removeProfilePic(){
      this.profilePic = null;
      this.profileFile = null;
    }
  },
  computed:{
    orgInitals(){
      let name = this.$store.state.organisation.shortName
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

      let initials = [...name.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
      ).toUpperCase();

      return initials
    }
    
  },
  validations: {
    o: {
      primaryColor: {
        required
      }
    }
  },
  // setup() {
  //   const refInputEl = ref(null)
  //   const previewEl = ref(null)

  //   const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
  //     profilePic = base64
  //   })

  //   return {
  //     refInputEl,
  //     previewEl,
  //     inputImageRenderer,
  //   }
  // },
};
</script>
