<template>
<b-overlay :show="loading" rounded="sm">
  <b-card>
    <!-- form -->
    <b-form
      @submit.prevent="saveOrganisation">
      <b-row>
        <b-col sm="12">
          <h6 class="section-label mb-1">Algemeen</h6>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Volledige naam" label-for="o-fullname">
            <b-form-input
              v-model="o.fullName"
              disabled
              :class="$v.o.fullName.$error ? 'is-invalid' : ''"
              placeholder="Volledige naam"
              name="o-fullname"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Weergave naam" label-for="o-shortname">
            <b-form-input
              v-model="o.shortName"
              name="o-shortname"
              :class="$v.o.shortName.$error ? 'is-invalid' : ''"
              disabled
              placeholder="Weergave naam"
            />
          </b-form-group>
        </b-col>
         <b-col sm="6">
          <b-form-group label="Website" label-for="o-website">
            <b-form-input
              v-model="o.website"
              name="o-website"
              :class="$v.o.website.$error ? 'is-invalid' : ''"
              :disabled="loading"
              placeholder="Website"
            />
          </b-form-group>
        </b-col>
         <b-col sm="6">
          <b-form-group label="Algemeen e-mailadres" label-for="o-mailEmail">
            <b-form-input
              v-model="o.mainEmail"
              name="o-mainEmail"
              :class="$v.o.mainEmail.$error ? 'is-invalid' : ''"
              :disabled="loading"
              placeholder="Algemeen e-mailadres"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Link privacyverklaring" label-for="o-privacypolicy">
            <b-form-input
              v-model="o.privacyUrl"
              name="o-privacypolicy"
              :class="$v.o.privacyUrl.$error ? 'is-invalid' : ''"
              placeholder="Link privacyverklaring"
            />
          </b-form-group>
        </b-col>
        <b-col md="8" class="mb-2">
          <b-row>
            <b-col sm="12">
              <h6 class="form-label mb-1 mt-2">Adres</h6>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Straat">
                <b-form-input
                  class="form-control"
                  v-model="o.address.street"
                  :class="$v.o.address.street.$error ? 'is-invalid' : ''"
                  :disabled="loading"
                  placeholder="Straat"
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Nr.">
                <b-form-input
                  class="form-control"
                  :disabled="loading"
                  v-model="o.address.housenumber"
                  :class="$v.o.address.housenumber.$error ? 'is-invalid' : ''"
                  placeholder="Nr."
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Bus">
                <b-form-input
                  class="form-control"
                  :disabled="loading"
                  v-model="o.address.addition"
                  :class="$v.o.address.addition.$error ? 'is-invalid' : ''"
                  placeholder="Bus"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Postcode">
                <b-form-input
                  class="form-control"
                  :disabled="loading"
                  v-model="o.address.postal"
                  :class="$v.o.address.postal.$error ? 'is-invalid' : ''"
                  placeholder="Postcode"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Plaats">
                <b-form-input
                  class="form-control"
                  :disabled="loading"
                  v-model="o.address.city"
                  :class="$v.o.address.city.$error ? 'is-invalid' : ''"
                  placeholder="Plaats"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="loading"
            type="submit"
          >
          <b-spinner v-if="loading" small></b-spinner>
            Opslaan
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Annuleren
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</b-overlay>
</template>

<script>
import { auth } from "@/firebase/firebaseConfig";
import { required, email, minLength, numeric, url } from "vuelidate/lib/validators";
import {
  BFormFile,
  BButton,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BOverlay,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      o: { ...this.$store.state.organisation, ...{} },
    };
  },
  methods: {
    async saveOrganisation(){
      if (!this.$v.$invalid) {
        this.loading=true;
         await this.$store.dispatch('updateOrganisation', {
           organisation: this.o,
           notify: (arg) => this.$toast(arg)});
           this.loading = false;
      } else {
        console.log(this.$v);
        this.$v.$touch();
      }
       
    },
    resetForm() {
      this.o = { ...this.$store.state.organisation, ...{} };
    },
  },
  computed: {
    accountVerified() {
      return auth.currentUser.emailVerified;
    },
  },
  validations: {
    o: {
      fullName: {
        required
      },
      shortName: {
        required
      },
      website: {
        required, 
      },
      privacyUrl: {
      },
      mainEmail: {
        required,
        email
      },
      address:{
          street: {
            required,
            minLength: minLength(2)
          },
          housenumber: {
            required,
            numeric
          },
          addition: {
            
          },
          postal: {
            required,
            numeric
          },
          city: {
            required
          }
      },

    }
  },
};
</script>
