<template>
  <div>
    <b-card>
      <div class="d-flex align-items-center mb-2">
        <feather-icon icon="UserIcon" size="18" />
        <h4 class="mb-0 ml-75">Aangepaste velden</h4>
      </div>
      <b-list-group class="mb-2">
        <b-list-group-item
          class="d-flex justify-content-between"
          :key="key"
          v-for="(field, key) in this.$store.state.organisation.customFields"
        >
          <span>
           <b-form-checkbox
            v-model="field.enabled"
            @change="fieldChanged(field, key)"
            class="mr-1 custom-control-success"
            name="enabled"
            switch
            inline
          />
            <span>{{ field.name }}</span>
          </span>
          <span>
            {{ getTypeLabel(field.type) }}
          </span>
        </b-list-group-item>
      </b-list-group>
      <b-button
        class="w-100"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="modalOpen = true"
        ><span class="mr-1">
          <feather-icon icon="PlusIcon" size="16" /> </span
        >Veld toevoegen</b-button
      >
    </b-card>
    <b-modal
      title="Veld toevoegen"
      hide-footer
      v-model="modalOpen"
      no-close-on-backdrop
    >
      <b-form @submit.prevent="addField">
        <b-overlay :show="loading" rounded="sm">
          <b-row>
            <!-- Field: name -->
            <b-col cols="12" md="12">
              <b-form-group label="Naam" label-for="name">
                <b-form-input
                  id="v-name"
                  :disabled="loading"
                  v-model="newField.name"
                  :class="$v.newField.name.$error ? 'is-invalid' : ''"
                  placeholder="Naam"
                />
              </b-form-group>
            </b-col>
            <!-- Field: Type -->
            <b-col cols="12" md="12">
              <b-form-group label="Type" label-for="type">
                <v-select
                  v-model="newField.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :disabled="loading"
                  :closeOnSelect="true"
                  :reduce="(type) => type.value"
                  :class="$v.newField.type.$error ? 'is-invalid' : ''"
                  :options="types"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-end">
            <b-button
              :disabled="loading"
              variant="outline-secondary"
              class="mr-1"
              @click="modalOpen = false"
            >
              Annuleren
            </b-button>
            <b-button :disabled="loading" variant="primary" type="submit">
              Veld toevoegen
            </b-button>
          </div>
        </b-overlay>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { db, FieldValue } from "@/firebase/firebaseConfig";
import { required, email, minLength, numeric } from "vuelidate/lib/validators";
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BSpinner,
    BListGroup,
    BListGroupItem,
    vSelect,
    BOverlay,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalOpen: false,
      loading: false,
      types: [
        {
          value: "boolean",
          label: "Checkbox",
        },
        {
          value: "string",
          label: "Tekst",
        },
      ],
      newField: {
        type: "string",
        name: "",
        enabled: true
      },
    };
  },
  created(){
    if(!this.$store.state.organisation.customFields){
      db
            .collection("organisations")
            .doc(this.$store.state.organisation.id)
            .update({
              customFields: [],
            });
    }
  },
  methods: {
    async addField() {
      if (!this.$v.$invalid) {
        this.loading = true;
        this.newField.id = this.getId();
        if (!this.$store.state.organisation.customFields) {
          await db
            .collection("organisations")
            .doc(this.$store.state.organisation.id)
            .update({
              customFields: [this.newField],
            });
        } else {
          await db
            .collection("organisations")
            .doc(this.$store.state.organisation.id)
            .update({
              customFields: FieldValue.arrayUnion(this.newField),
            });
        }
        this.newField = {
          enabled: true,
          name: '',
          type: 'string'
        }
        this.loading = false;
        this.modalOpen = false;
      } else {
        this.$v.$touch();
      }
    },
    getId(){
      return this.checkId(this.newField.name.toLowerCase().trim().replace(" ", "_"), 0);
    },
    checkId(id, count){
      
      var idToCheck = id;
      if(count > 0){
        idToCheck = id + "_" + count.toString();
      }
      count++;
       var index = this.$store.state.organisation.customFields.findIndex(f => f.id == idToCheck);
       if(index > -1){
         return this.checkId(id, count);
       } else {
         return idToCheck;
       }
    },
    async fieldChanged(field, key){
      this.$store.state.organisation.customFields[key] = field;
      await db
            .collection("organisations")
            .doc(this.$store.state.organisation.id)
            .update({
              customFields: this.$store.state.organisation.customFields,
            });
    },
    getTypeLabel(type){
      var index = this.types.findIndex(element => element.value == type);
      if(index > -1){
        return this.types[index].label;
      }
      return "";
    }
  },
  validations: {
    newField: {
      name: {
        required,
      },
      type: {
        required,
      },
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>