<template>
  <div>
    <b-card>
      <div class="d-flex align-items-center mb-2">
        <feather-icon icon="ClipboardIcon" size="18" />
        <h4 class="mb-0 ml-75">Inschrijvingsformulier</h4>
      </div>
       <b-overlay :show="loading" rounded="sm">
           <b-col sm="12">
            <h6 class="section-label mb-1 mt-2">Velden</h6>
          </b-col>
      <b-list-group class="mb-2">
        <b-list-group-item
          class="d-flex justify-content-between"
          :key="key"
          v-for="(field, key) in fields"
        >
          <span>
           <b-form-checkbox
            v-model="fields[key]"
            class="mr-1 custom-control-success"
            name="enabled"
            switch
            inline
          />
            <span>{{ labels[key] }}</span>
          </span>
        </b-list-group-item>
      </b-list-group>
      <b-button
        class="w-100"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="saveSettings"
        >Opslaan</b-button
      >
       </b-overlay>
    </b-card>
    
  </div>
</template>

<script>
import { db, FieldValue } from "@/firebase/firebaseConfig";
import { required, email, minLength, numeric } from "vuelidate/lib/validators";
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BSpinner,
    BListGroup,
    BListGroupItem,
    vSelect,
    BOverlay,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      config: {},
      fields: {
         'firstname': true,
         'lastname': true,
         'displayName': true, 
         'birthday': true,
         'gender': true,
         'type': true,
        'phone': true,
        'email': true,
        'address': true
      },
      labels: {
          'firstname': 'Voornaam',
         'lastname': 'Naam',
         'displayName': 'Weergavenaam', 
         'birthday': 'Geboortedatum',
         'gender': 'Geslacht',
         'type': 'Type',
        'phone': 'Telefoonnummer',
        'email': 'E-mailadres',
        'address': 'Adres'
      }
      
    };
  },
  created(){
    this.getSignUpConfig();
  },
  methods: {
    async getSignUpConfig(){
        var snap = await db
            .collection("organisations")
            .doc(this.$store.state.organisation.id)
            .collection('config')
            .doc('sign-up')
            .get();
        if(snap.exists){
            this.config = snap.data();
            if(!this.config.fields){
                this.config.fields = {};
                
            }
            this.checkCustomFields();
            this.fields = this.config.fields;
        } else {

        }
    },
    checkCustomFields(){
        if(this.$store.state.organisation.customFields){
            this.$store.state.organisation.customFields.forEach(f => {
                if(this.config.fields[f.id] == null){
                    this.config.fields[f.id] = true;
                }
                this.labels[f.id] = f.name;
                
            })
            }
    },
    async saveSettings(){
        this.loading = true;
        await db
            .collection("organisations")
            .doc(this.$store.state.organisation.id)
            .collection('config')
            .doc('sign-up').set({fields: this.fields}, {merge: true});
        this.loading = false;
    }
    
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>