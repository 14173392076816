<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon icon="DollarSignIcon" size="18" />
            <h4 class="mb-0 ml-75">Facturering</h4>
          </div>
          <!-- alert -->
        <b-col v-if="organisation.plan == 'trial' &&trialLeft && trialLeft > 0" cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Proefperiode
            </h4>
            <div class="alert-body">
               Je hebt nog <strong>{{trialLeft}} {{trialLeft == 1 ? 'dag' : 'dagen'}}</strong> over in je proefperiode.
            </div>
          </b-alert>
        </b-col>
         <b-col v-if="organisation.plan == 'trial' && trialLeft != null && trialLeft <= 0" cols="12" class="mt-75">
          <b-alert show variant="danger" class="mb-50">
            <h4 class="alert-heading">
              Proefperiode
            </h4>
            <div class="alert-body">
               Je proefperiode is verlopen.
            </div>
          </b-alert>
        </b-col>
        <b-col v-if="organisation.plan && organisation.plan == 'basic'" cols="12" class="mt-75">
          <b-alert show variant="primary" class="mb-50">
            <div class="alert-body">
               Je gebruikt de gratis basisversie van Tapp.
            </div>
          </b-alert>
        </b-col>
        <b-col v-if="organisation.plan && organisation.plan == 'standard'" cols="12" class="mt-75">
          <b-alert show variant="primary" class="mb-50">
            <div class="alert-body">
               Je jaarlijkse factuur wordt geschat op <strong>€ {{Math.round((((this.organisation.userCount)*4) + Number.EPSILON) * 100) / 100 }}</strong>
            </div>
          </b-alert>
        </b-col>
         <b-col v-if="organisation.plan && organisation.plan == 'custom'" cols="12" class="mt-75">
          <b-alert show variant="primary" class="mb-50">
            <div class="alert-body">
               Je hebt een abonnement op maat. Contacteer ons via e-mail voor meer inlichtingen.
            </div>
          </b-alert>
        </b-col >
        
        <!--/ alert -->
        
        </b-col>
        <b-col cols="12" class="mt-75">
          <p>Contacteer ons via <a href="mailto:vincent@tapp.be">vincent@tapp.be</a> om van abonnement te veranderen.</p>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import moment from 'moment';
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BSpinner,
  BAlert
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BSpinner,
    BAlert
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      organisation: this.$store.state.organisation
    };
  },
  methods: {
    
  },
  computed:{
    trialLeft(){
      if(this.organisation){
        if(this.organisation.trialExpires){
          var now = moment();
          var end = moment(this.organisation.trialExpires.seconds*1000);
          return end.diff(now, 'days')+1;
        } else {
          return 0;
        }
      } else {
        return null;
      }
    }
    
  }
};
</script>
